import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../../components/layout'
import PrivateRoute from '../../components/privateRoute'

const NewsletterArchive = ({ data }) => {
  const newslettersItems = data.allSanityNewsletter.edges.map(({ node }) => (
    <li key={node.id}>
      <Link to={`/news/${node.slug.current}`}>{node.title}</Link>
    </li>
  ))

  return (
    <Layout>
      <h1>Newsletter Archive</h1>
      <ul>{newslettersItems}</ul>
    </Layout>
  )
}

export default props => {
  return <PrivateRoute component={NewsletterArchive} {...props} />
}

export const query = graphql`
  query AllNewsletterQuery {
    allSanityNewsletter(sort: { fields: publishDate, order: DESC }) {
      edges {
        node {
          id
          title
          publishDate
          slug {
            current
          }
          file {
            asset {
              url
            }
          }
          _rawContent
        }
      }
    }
  }
`
